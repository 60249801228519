import Vue from 'vue';
import Router from 'vue-router';

// Views
const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
const Single = () => import(/* webpackChunkName: "single" */ '@/views/Single.vue')

// Partials
const Privacy = () => import(/* webpackChunkName: "privacy" */ '@/views/partials/Privacy.vue')
const Terms = () => import(/* webpackChunkName: "terms" */ '@/views/partials/Terms.vue')

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
	{
		path: '',
		component: Single,
		children: [
			{
				name: 'privacy',
				path: '/privacy',
				component: Privacy,
			},
			{
				name: 'terms',
				path: '/terms',
				component: Terms,
			}
		]
	}
  ],
  scrollBehavior(to, from, savedPosition) {
	  if (savedPosition) {
		  return savedPosition
	  }

	  if (to.name !== from.name) {
		  return {
			  x: 0,
			  y: 0,
		  }
	  }
  }
});
