import Vue from 'vue'

// Plugins
import VueMeta from 'vue-meta'

import router from './router'
import store from './store'
import './registerServiceWorker'
import App from './App.vue'

Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
