<template>
	<router-view id="app"></router-view>
</template>

<script>
const GTM_ID = process.env.GTM_ID || process.env.VUE_APP_GTM_ID

export default {
	name: 'app',
	metaInfo: () => ({
		title: 'Quick Patient',
		titleTemplate: '%s | Contactless Check-in. No new patient forms.',
		meta: [
			{
				name: 'description',
				content: `Safe and convenient contact free check-in for your doctor's office. Quick Patient allows you to check in curbside or via telehealth instantly and safely.`,
			},
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, maximum-scale=5'
			},
		],
		link: [
			{
				rel: 'stylesheet',
				href: '//fonts.googleapis.com/icon?family=Material+Icons'
			},
			{
				rel: 'stylesheet',
				href: '//use.typekit.net/kku0whw.css',
			},
		],
		script: [
			{
				vmid: 'gtm',
				type: 'application/javascript',
				innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}')`
			},
			// {
			// 	type: 'application/javascript',
			// 	src: `https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`,
			// 	async: true,
			// },
			// {
			// 	vmid: 'gtm',
			// 	type: 'application/javascript',
			// 	innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GTM_ID}');`,
			// },
		],
		__dangerouslyDisableSanitizersByTagID: {
			description: ['gtm']
		}
	}),
}
</script>

<style lang="scss">
@import 'assets/scss/partials';

#app {
	overflow-x: hidden;
}
</style>
